<template>
  <div>
    <page-loading :show="loading" />
    <form @submit.prevent="submitRequest">
      <b-alert :variant="messageType" :show="messageShow" dismissible @dismissed="closeAlert">
        <div v-html="messageAlert"></div>
      </b-alert>
      <div class="form--group" :class="{ 'has-error': validation.hasError('projectType') }">
        <div class="left--col">
          <span>{{ $t('helpDesk.helpFindResidence.projectType') }}</span>
          <span class="required">*</span>
        </div>
        <div class="right--col">
          <div
            class="custom-control custom-radio my-2"
            v-for="item in projectTypeList"
            :key="'project-type-' + item.id"
          >
            <input
              type="radio"
              class="custom-control-input"
              :value="item.id"
              :checked="item.checked"
              :id="'project-type-' + item.id"
              name="projectType[]"
              @input="projectTypeInputHandler"
            />
            <label class="custom-control-label" :for="'project-type-' + item.id">
              <span v-if="item.name">{{ item.name }}</span>
            </label>
            <input
              v-if="!item.name"
              class="basic--input"
              id="otherType"
              name="otherType"
              type="text"
              :placeholder="$t('helpDesk.helpFindResidence.placeholder.otherType')"
              v-model="otherType"
            />
            <div v-if="!item.name"></div>
          </div>
          <span class="val-error" v-if="validation.hasError('projectType')">{{
            validation.firstError('projectType')
          }}</span>
        </div>
      </div>
      <div class="form--group" :class="{ 'has-error': validation.hasError('city') }">
        <div class="left--col">
          <label for="city">
            <span>{{ $t('helpDesk.helpFindResidence.city') }}</span>
            <span class="required">*</span>
          </label>
        </div>
        <div class="right--col">
          <input
            class="basic--input"
            id="city"
            name="city"
            type="text"
            :placeholder="$t('helpDesk.helpFindResidence.placeholder.city')"
            v-model="city"
          />
          <span class="val-error" v-if="validation.hasError('city')">{{
            validation.firstError('city')
          }}</span>
        </div>
      </div>
      <div class="form--group" :class="{ 'has-error': validation.hasError('priceRange') }">
        <div class="left--col">
          <span>{{ $t('helpDesk.helpFindResidence.priceRange') }}</span>
          <span class="required">*</span>
        </div>
        <div class="right--col">
          <div
            class="custom-control custom-checkbox my-2"
            v-for="item in priceRangeList"
            :key="'price-range-' + item.id"
          >
            <input
              type="checkbox"
              class="custom-control-input"
              :value="item.id"
              :checked="item.checked"
              :id="'price-range-' + item.id"
              name="priceRange[]"
              @input="priceRangeInputHandler"
            />
            <label class="custom-control-label" :for="'price-range-' + item.id">{{
              item.name
            }}</label>
          </div>
          <span class="val-error" v-if="validation.hasError('priceRange')">{{
            validation.firstError('priceRange')
          }}</span>
        </div>
      </div>
      <div class="form--group" :class="{ 'has-error': validation.hasError('reference') }">
        <div class="left--col">
          <span>{{ $t('helpDesk.helpFindResidence.reference') }}</span>
        </div>
        <div class="right--col">
          <div
            class="custom-control custom-radio my-2"
            v-for="item in referenceList"
            :key="'reference-' + item.id"
          >
            <input
              type="radio"
              class="custom-control-input"
              :value="item.id"
              :checked="item.checked"
              :id="'reference-' + item.id"
              name="reference[]"
              @input="referenceInputHandler"
            />
            <label class="custom-control-label" :for="'reference-' + item.id">
              <span v-if="item.name">{{ item.name }}</span>
            </label>
            <input
              v-if="!item.name"
              class="basic--input"
              id="otherReference"
              name="otherReference"
              type="text"
              :placeholder="$t('helpDesk.helpFindResidence.placeholder.otherReference')"
              v-model="otherReference"
            />
            <div v-if="!item.name"></div>
          </div>
          <span class="val-error" v-if="validation.hasError('reference')">{{
            validation.firstError('reference')
          }}</span>
        </div>
      </div>
      <div class="form--group" :class="{ 'has-error': validation.hasError('name') }">
        <div class="left--col">
          <label for="name">
            <span>{{ $t('helpDesk.helpFindResidence.name') }}</span>
            <span class="required">*</span>
          </label>
        </div>
        <div class="right--col">
          <input
            class="basic--input"
            id="name"
            name="name"
            type="text"
            :placeholder="$t('helpDesk.helpFindResidence.placeholder.name')"
            v-model="name"
          />
          <span class="val-error" v-if="validation.hasError('name')">{{
            validation.firstError('name')
          }}</span>
        </div>
      </div>
      <div class="form--group" :class="{ 'has-error': validation.hasError('phone') }">
        <div class="left--col">
          <label for="phone">
            <span>{{ $t('helpDesk.helpFindResidence.phone') }}</span>
            <span class="required">*</span>
          </label>
        </div>
        <div class="right--col">
          <cleave
            id="phone"
            v-model="phone"
            :class="validation.hasError('phone') ? 'is-invalid' : ''"
            :options="phoneCleaveOption"
            autocomplete="phone"
            class="basic--input"
            type="text"
            name="phone"
            :placeholder="$t('helpDesk.helpFindResidence.placeholder.phone')"
          />
          <span class="val-error" v-if="validation.hasError('phone')">{{
            validation.firstError('phone')
          }}</span>
        </div>
      </div>
      <div class="form--group" :class="{ 'has-error': validation.hasError('email') }">
        <div class="left--col">
          <label for="email">
            <span>{{ $t('helpDesk.helpFindResidence.email') }}</span>
            <span class="required">*</span>
          </label>
        </div>
        <div class="right--col">
          <input
            class="basic--input"
            name="email"
            id="email"
            type="email"
            :placeholder="$t('helpDesk.helpFindResidence.placeholder.email')"
            v-model="emailInput"
          />
          <span class="val-error" v-if="validation.hasError('email')">{{
            validation.firstError('email')
          }}</span>
        </div>
      </div>
      <div class="d-flex justify-content-center">
        <button type="submit" class="btn btn-primary">
          {{ $t('helpDesk.helpFindResidence.sendForm') }}
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import HelperMixin from '@/mixins/helpers';
const PageLoading = () => import('@/components/content-loading/page-loading');

// import VueRecaptcha from 'vue-recaptcha';
import { Validator } from 'simple-vue-validator';
import Cleave from 'vue-cleave-component';
import 'cleave.js/dist/addons/cleave-phone.id.js';
export default {
  name: 'DeveloperPartnerForm',
  mixins: [HelperMixin],
  components: {
    PageLoading,
    Cleave,
  },
  computed: {
    ...mapState({
      messageAlert: (state) => state.global.message,
      messageType: (state) => state.global.messageType,
      user: (state) => state.global.user,
      propertyTypes: (state) => state.v2.masters.propertyTypes,
    }),
    emailInput: {
      get() {
        return this.email;
      },
      set(val) {
        this.email = this.cleanEmail(val);
      },
    },
    projectType() {
      let text = '';
      if (this.selectedProjectType) {
        if (this.selectedProjectType.name) text = this.selectedProjectType.name;
        else text = this.cleanInput(this.otherType);
      }
      return text;
    },
    priceRange() {
      let text = '';
      for (let item of this.priceRangeList) {
        if (item.checked) {
          if (text) text += ', ';
          text += item.name;
        }
      }
      return text;
    },
    reference() {
      let text = '';
      if (this.selectedReference) {
        if (this.selectedReference.name) text = this.selectedReference.name;
        else text = this.cleanInput(this.otherReference);
      }
      return text;
    },
  },
  data: () => ({
    loading: false,
    email: '',
    name: '',
    phone: '',
    otherReference: '',
    otherType: '',
    city: '',
    messageShow: false,
    selectedProjectType: null,
    selectedReference: null,
    projectTypeList: [
      // {
      //   id: -1,
      //   name: 'Rumah Subsidi',
      //   checked: false,
      // },
      // {
      //   id: 1,
      //   name: 'Rumah',
      //   checked: false,
      // },
      // {
      //   id: 2,
      //   name: 'Apartemen',
      //   checked: false,
      // },
      // {
      //   id: 3,
      //   name: null,
      //   checked: false,
      // },
    ],
    priceRangeList: [
      {
        id: 1,
        name: '< 500 Juta',
        checked: false,
      },
      {
        id: 2,
        name: '500 Juta - 1 Miliar',
        checked: false,
      },
      {
        id: 3,
        name: '1 - 2 Miliar',
        checked: false,
      },
      {
        id: 4,
        name: '> 2 Miliar',
        checked: false,
      },
    ],
    referenceList: [
      {
        id: 1,
        name: 'Dari Google',
        checked: false,
      },
      {
        id: 2,
        name: 'Referensi Teman',
        checked: false,
      },
      {
        id: 3,
        name: 'Event Properti',
        checked: false,
      },
      {
        id: 4,
        name: 'Iklan',
        checked: false,
      },
      {
        id: 5,
        name: 'Surat Kabar',
        checked: false,
      },
      {
        id: 6,
        name: null,
        checked: false,
      },
    ],
    phoneCleaveOption: {
      phone: true,
      phoneRegionCode: 'id',
      noImmediatePrefix: true,
    },
  }),
  validators: {
    email(value) {
      return Validator.value(value)
        .required(this.$i18n.t('errors.loginRegister.email.required'))
        .email(this.$i18n.t('errors.loginRegister.email.invalid'))
        .maxLength(255, this.$i18n.t('errors.email.max', { maxChar: 255 }));
    },
    name(value) {
      return Validator.value(value).required(this.$i18n.t('errors.name.required'));
    },
    phone(value) {
      return Validator.value(value).required(this.$i18n.t('errors.phone.required'));
    },
    projectType(value) {
      return Validator.value(value).required(this.$i18n.t('errors.residenceType.required'));
    },
    priceRange(value) {
      return Validator.value(value).required(this.$i18n.t('errors.priceRange.required'));
    },
    city(value) {
      return Validator.value(value).required(this.$i18n.t('errors.city.required'));
    },
  },
  async mounted() {
    if (this.user) {
      this.email = this.user.email;
      this.name = this.user.name;
    }
    if (!this.propertyTypes || this.propertyTypes.length === 0) {
      await this.$store.dispatch('v2/masters/getPropertyTypes');
    }
    this.projectTypeList = [
      ...this.propertyTypes
        .filter((item) => {
          return item.id !== null;
        })
        .map((element) => {
          return {
            id: element.id,
            name: element.name,
            checked: false,
          };
        }),
      {
        id: this.propertyTypes.length + 1,
        name: null,
        checked: false,
      },
    ];
    console.log('PROJECT TYPE LIST: ', this.projectTypeList);
  },
  methods: {
    projectTypeInputHandler(evt) {
      let projectType = this.projectTypeList.find((item) => {
        return item.id == evt.target.value;
      });
      this.selectedProjectType = projectType;

      if (projectType.name) this.otherType = '';
    },
    priceRangeInputHandler(evt) {
      let priceRange = this.priceRangeList.find((item) => {
        return item.id == evt.target.value;
      });
      priceRange.checked = !priceRange.checked;
    },
    referenceInputHandler(evt) {
      let reference = this.referenceList.find((item) => {
        return item.id == evt.target.value;
      });
      this.selectedReference = reference;

      if (reference.name) this.otherReference = '';
    },
    async submitRequest() {
      try {
        this.loading = true;
        const isValid = await this.$validate();
        if (isValid) {
          let formData = new FormData();
          formData.append('email', this.cleanInput(this.email));
          formData.append('name', this.cleanInput(this.name));
          formData.append('phone_number', this.phone);
          formData.append('reference', this.reference);
          formData.append('property_type', this.projectType);
          formData.append('price', this.priceRange);
          formData.append('location', this.cleanInput(this.city));
          try {
            let response = await this.$store.dispatch('global/helpFindResidence', formData);
            if (response && response.type === 'success') {
              await this.$swal(
                this.$t('general.success'),
                this.$t('helpDesk.helpFindResidence.success'),
                'success',
              );
              this.resetForm();
            }
          } catch (err) {
            console.log('Error request help find residence: ', err);
          }
        } else {
          this.scrollToErrorSection(this.$el);
        }
        // eslint-disable-next-line no-empty
      } catch (_) {
      } finally {
        this.loading = false;
      }
    },
    closeAlert() {
      this.$store.commit('global/RESET_ALERT');
    },
    resetForm() {
      this.email = '';
      this.name = '';
      this.phone = '';
      this.otherReference = '';
      this.otherType = '';
      this.city = '';
      this.selectedProjectType = null;
      this.selectedReference = null;
      for (let item of this.projectTypeList) {
        item.checked = false;
      }
      for (let item of this.priceRangeList) {
        item.checked = false;
      }
      for (let item of this.referenceList) {
        item.checked = false;
      }
      this.validation.reset();
    },
  },
};
</script>
