<template>
  <section class="agent--partner-wrapper">
    <div class="title--text">
      {{ $t('helpDesk.helpFindResidence.title') }}
    </div>
    <help-find-residence-form />
  </section>
</template>

<script>
import HelpFindResidenceForm from '@/components/help-desk/help-find-residence/help-find-residence-form';

export default {
  components: {
    HelpFindResidenceForm,
  },
};
</script>
